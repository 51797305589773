* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.doc {
  background-color: white;
  width: 100vw;
  height: 100vh;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center;
  flex-direction: row;
  position: relative; */
  display: grid;
  /* grid-template-columns: 15% 85%; */
}

.doc-container {
  display: flex;
  position: relative;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  /* background-color: #f1f7f7; */
  width: 100%;
  height: 100%;
  /* margin-top: 40px; */
  /* padding: 1rem 3rem 1rem 3rem; */
  padding-bottom: 30px;
}

.doc-head {
  /* background-color: yellow; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  border-bottom: 0.0625rem solid #e7eaf3;
  border-top: 0.0625rem solid #e7eaf3;
  margin-bottom: 10px;
  padding: 10px 20px;
  padding-right: 40px;
}

.doc-heading {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.doc-heading h1 {
  color: #173e67;
  text-align: center;
  font-size: 1.7rem;
}

.doc-heading p {
  color: #888eb0;
  font-size: 0.9rem;
}

.all-doc {
  /* background-color: blue; */
  height: 100%;
  width: 95%;
  display: flex;
  align-items: center;
  /* position: relative; */
  /* justify-content: center; */
  flex-direction: column;
}

.doc-table-head {
  /* background-color: yellow; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 10px 0 10px 0;
}

.doc-table-head h3 {
  color: rgba(0, 0, 0, 0.62);
  font-size: 0.9rem;
}

@media screen and (max-width: 985px) {
  .doc-head h1 {
    font-size: 1.2rem;
  }
  .doc {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: tomato; */
  }
  .doc-container {
    display: flex;
    position: relative;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    /* background-color: #f1f7f7; */
    width: 100%;
    height: 100%;
    margin-top: 10px;
    /* padding: 1rem 3rem 1rem 3rem; */
  }
  .ham {
    display: unset;
    /* z-index: 6; */
  }
}
@media screen and (max-width: 500px) {
  .doc-head h1 {
    font-size: 1rem;
  }
}
