* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#margin,
#batch {
  border: 1px solid rgba(0, 0, 0, 0.41);
  border-radius: 4px;
}

.expenses {
  /* background-color: red; */
  width: 100vw;
  height: 100vh;
  display: grid;
  overflow-y: scroll;
}

.expense {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.expense-container {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  overflow-y: scroll;
}

.expense-head {
  /* background-color: yellow; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  border-bottom: 0.0625rem solid #e7eaf3;
  border-top: 0.0625rem solid #e7eaf3;
  margin-bottom: 10px;
  padding: 10px 20px;
  padding-right: 40px;
}

.pricing-table td,
.pricing-table th {
  font-size: 0.8rem !important;
}

.dateFilter {
  display: none;
}

.expense-heading {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.expense-heading h1 {
  color: #173e67;
  font-size: 1.7rem;
  text-align: center;
}

.expense-heading p {
  color: #888eb0;
  font-size: 0.9rem;
}

.full-expense {
  /* background-color: blue; */
  height: 100%;
  width: 95%;
  display: flex;
  align-items: center;
  /* position: relative; */
  justify-content: center;
  flex-direction: row;
}
.expense-table {
  border: 1px solid #e7eaf3;
}
.all-expenses {
  /* background-color: blue; */
  height: 100%;
  width: 80%;
  display: flex;
  align-items: center;
  /* position: relative; */
  /* justify-content: center; */
  flex-direction: column;
  margin-right: 10px;
}

.expense-table-head {
  /* background-color: yellow; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  /* margin: 10px 0 10px 0; */
}

.expense-table-head h3 {
  color: rgba(0, 0, 0, 0.62);
  font-size: 0.9rem;
}
.expense-creditors {
  /* width: 80%; */
  height: 50%;
}

.pricing-container {
  width: 100%;
}
@media screen and (max-width: 985px) {
  .expense-head h1 {
    font-size: 1.2rem;
  }
  .expenses {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: tomato; */
  }
  .ham {
    display: unset;
    /* z-index: 6; */
  }
  .expense-container {
    display: flex;
    position: relative;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    /* background-color: #f1f7f7; */
    width: 100%;
    height: 100%;
    margin-top: 10px;
    /* padding: 1rem 3rem 1rem 3rem; */
  }
}
@media screen and (max-width: 900px) {
  .full-expense {
    display: flex;
    align-items: center;
    justify-content: unset;
    flex-direction: column;
  }
  .expense-creditors {
    width: 80%;
    height: 30%;
  }
  .all-expenses {
    height: 70%;
    margin-right: unset;
  }
  .expense-date-container {
    display: none;
  }
  .dateFilter {
    display: unset;
  }
}

@media screen and (max-width: 500px) {
  .expense-head h1 {
    font-size: 1rem;
  }
  .all-expenses {
    width: 100%;
  }
}
