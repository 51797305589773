.input {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 70%;
}

.input label {
  font-size: 0.8rem;
  color: rgb(110, 110, 110);
  /* font-weight: 300; */
}

.custom-med-form {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
}

.custom-med-form input,
.custom-med-form select {
  width: 20% !important;
  min-width: 190px !important;
}

.custom-med-form .input {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}

#SalesDate,
#MortalityDate,
#ExpenseDate,
#ConsumptionDate,
#PurchaseDate,
#ItemDate,
#LotNo,
#InvoiceNo,
#Reference,
#ExpenseType,
#ExpenseHead,
#FeedType,
#BirdType,
#DrugName,
#Batch,
#BagWeight,
#Qty,
#BagQtyUsed,
#SizeQtyUsed,
#SatchetQtyUsed,
#Item,
#Amount,
#UnitPrice,
#PmtMethod,
#Debtor,
#Creditor,
#BankName,
#UpdType,
#RecId,
#TxnType,
#txnType,
#TxnDate,
#AssetType,
#FADesc,
#FACode,
#Lifespan,
#FirstName,
#LastName,
#Email,
#SignOnName,
#ConfirmPassword,
#UserPassword,
#Weight,
#Other,
#Unit,
#DrugForm {
  width: 100%;
  border: 0.1px solid rgb(126, 136, 195);
  border-radius: 3px;
  height: 35px;
  padding-left: 10px;
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  background-color: rgba(155, 155, 155, 0.03);
}

.bird-sale,
.other-sales,
.doc-purchase,
.mortality,
.drug-purchase,
.drug-consumed,
.feed-purchase,
.feed-consumption,
.capexs,
.expense {
  background-color: white;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
  left: 0;
}
.advert {
  background-color: rgb(247, 247, 247);
  border: 0.5px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100% !important;
}

/* .expense {
  left: 0 !important;
} */

.hide-bird-sale,
.hide-other-sales,
.hide-doc-purchase,
.hide-mortality,
.hide-drug-purchase,
.hide-drug-consumed,
.hide-feed-purchase,
.hide-feed-consumption,
.hide-expense,
.hide-capexs {
  top: -100%;
  display: none;
}

.form-bird-sale,
.form-other-sales,
.form-doc-purchase,
.form-mortality,
.form-drug-purchase,
.form-drug-consumed,
.form-feed-purchase,
.form-feed-consumption,
.form-expense,
.form-capex {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.bird-type-container,
.sale-qty-container,
.qty-container,
.expense-type-container,
.double-input {
  width: 70%;
  display: grid;
  /* justify-items: center; */
  grid-template-columns: repeat(2, 1fr);
}

.bird-type-container .input,
.qty-container .input,
.sale-qty-container .input,
.expense-type-container .input,
.double-input .input {
  width: 90%;
}

.upd-type,
.rec-id {
  display: none !important;
}

.new-order-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.btn-order {
  background-color: white;
  border: 1px solid #90b6f0;
  margin-top: 5px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-family: "Nunito", sans-serif;
  color: #2070e7;
  font-weight: 600;
  transition: all ease-in-out 0.4s;
}
.btn-discard {
  background-color: white;
  border: 1px solid #fd4343;
  margin-top: 5px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-family: "Nunito", sans-serif;
  color: #fd4343;
  transition: all ease-in-out 0.4s;
  margin-left: 10px;
  font-weight: 600;
}

.btn-order:hover {
  background-color: #90b6f0 !important;
  color: white;
}

.btn-discard:hover {
  color: white;
  background-color: #fd4343;
}

.form-head {
  color: #1e2022;
  align-self: flex-start;
  opacity: 0.3;
  /* font-size: 0.9rem; */
  margin-bottom: 15px;
}
@media screen and (max-width: 500px) {
  .hide-bird-sale,
  .hide-other-sales,
  .hide-doc-purchase,
  .hide-mortality,
  .hide-drug-purchase,
  .hide-drug-consumed,
  .hide-feed-purchase,
  .hide-feed-consumption,
  .hide-expense,
  .hide-capexs {
    top: -100%;
    display: none;
  }

  .bird-sale,
  .other-sales,
  .doc-purchase,
  .mortality,
  .drug-purchase,
  .drug-consumed,
  .feed-purchase,
  .feed-consumption,
  .capexs,
  .expense {
    background-color: white;
    border-radius: 10px;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    padding: 20px;
    /* transition: all ease-in-out 0.4s; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .form-bird-sale,
  .form-other-sales,
  .form-doc-purchase,
  .form-mortality,
  .form-drug-purchase,
  .form-drug-consumed,
  .form-feed-purchase,
  .form-feed-consumption,
  .form-expense,
  .form-capex {
    /* background-color: red; */
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 600px;
    overflow-x: hidden;
    /* padding-top: 100px; */
  }
  .form-head {
    font-size: 1rem;
  }
}

@media screen and (max-width: 750px) {
  .bird-sale,
  .other-sales,
  .doc-purchase,
  .mortality,
  .drug-purchase,
  .drug-consumed,
  .feed-purchase,
  .feed-consumption,
  .capexs,
  .expense {
    background-color: white;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    height: 100%;
    left: 0;
  }

  .advert {
    height: 100%;
  }
}
