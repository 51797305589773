* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.drug {
  background-color: white;
  width: 100vw;
  height: 100vh;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center;
  flex-direction: row;
  position: relative; */
  display: grid;
  /* grid-template-columns: 15% 85%; */
}

.drug-container {
  display: flex;
  position: relative;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  /* background-color: #f1f7f7; */
  width: 100%;
  height: 100%;
  /* margin-top: 40px; */
  /* padding: 1rem 3rem 1rem 3rem; */
  padding-bottom: 30px;
}

.drug-head {
  /* background-color: yellow; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  border-bottom: 0.0625rem solid #e7eaf3;
  border-top: 0.0625rem solid #e7eaf3;
  margin-bottom: 10px;
  padding: 10px 20px;
  padding-right: 40px;
  text-align: center;
}

.drug-heading {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.drug-heading h1 {
  color: #173e67;
  font-size: 1.7rem;
}

.drug-heading p {
  color: #888eb0;
  font-size: 0.9rem;
}

.all-drug {
  /* background-color: blue; */
  height: 100%;
  width: 95%;
  display: flex;
  align-items: center;
  /* position: relative; */
  /* justify-content: center; */
  flex-direction: column;
}

.drug-table-head {
  /* background-color: yellow; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 10px 0 10px 0;
}

.drug-table-head h3 {
  color: rgba(0, 0, 0, 0.62);
  font-size: 0.9rem;
}

.drug-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 0 50px;
  /* margin-top: 5px; */
  font-size: 0.85rem;
  border-top: 1px solid #888eb0;
}
.capex-creditors,
.capex-debtors {
  padding: 5px 20px;
  margin: 0 10px;
  border-radius: 7px;
  border: 0.0625rem solid #e7eaf3;
}
@media screen and (max-width: 985px) {
  .drug-head h1 {
    font-size: 1.2rem;
  }
  .drug {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: tomato; */
  }
  .drug-container {
    display: flex;
    position: relative;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    /* background-color: #f1f7f7; */
    width: 100%;
    height: 100%;
    margin-top: 10px;
    /* padding: 1rem 3rem 1rem 3rem; */
  }
  .ham {
    display: unset;
    /* z-index: 6; */
  }
}
@media screen and (max-width: 781px) {
  .all-capex-mini-list {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }
  .all-capex-mini-wrapper {
    /* align-items: unset; */
    justify-content: unset;
  }
  .capex-mini {
    margin-left: unset;
    margin-top: 10px;
  }
}
@media screen and (max-width: 515px) {
  .capex-debt {
    display: none;
  }
  .capex-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .capex-debtors,
  .capex-creditors {
    padding: 0;
  }
}
@media screen and (max-width: 500px) {
  .drug-head h1 {
    font-size: 1rem;
  }
}
@media screen and (max-width: 411px) {
  .capex-mini {
    width: 100%;
  }
}
