.register-container {
  /* background-color: blue; */
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0.633), rgba(0, 0, 0, 0.865)),
    url("../Photos/chicksbg.jpg") center/cover no-repeat;
}

/* .banner {
  background: linear-gradient(rgba(0, 104, 163, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://dl.airtable.com/.attachmentThumbnails/985e648e3f89ad4cd780a425d8f09465/fc0c8432")
      center/cover no-repeat;
  height: 100%;
  width: 50%;
} */

.signup-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /* height: 90%; */
  width: 40%;
  /* background-color: aqua; */
}

.user-validation {
  display: flex;
  /* background-color: red; */
  width: 80%;
  min-width: 320px;
  align-items: center;
  justify-content: top;
  overflow-x: hidden;
  flex-direction: column;
  /* margin-top: 20px; */
  /* padding-left: 20px; */
  background-color: white;
  border-radius: 10px;
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
  /* height: 95%; */
  padding: 0 10px;
}
.user-validation-wrapper {
  /* background-color: blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
}
.input-2 {
  margin-bottom: 10px;
}

.validation-header {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
  color: #173e67;
}

.register-logo {
  color: white;
  margin-bottom: 20px;
}

.btn-create {
  border: unset;
  background-color: #377dff;
  width: 80%;
  color: white;
  border-radius: 10px;
  padding: 10px;
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: all ease-in-out 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.btn-create:hover {
  transform: scale(1.02);
  box-shadow: 2.2px 2.3px 2.4px rgba(0, 0, 0, 0.02),
    5.5px 5.6px 6px rgba(0, 0, 0, 0.028),
    10.8px 11px 11.4px rgba(0, 0, 0, 0.035),
    19.8px 20.3px 20.8px rgba(0, 0, 0, 0.042),
    37px 37.9px 39.2px rgba(0, 0, 0, 0.05), 84px 86px 94px rgba(0, 0, 0, 0.07);
}

.redirect-login {
  margin: 20px 0;
  color: #173e67;
}

.redirect-login a {
  color: #377dff;
  text-decoration: none;
}
@media screen and (max-width: 500px) {
  h2 {
    font-size: 1rem;
  }
  .redirect-login {
    font-size: 0.9rem;
  }
}
