* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.doc-form {
  background-color: white;
  width: 100%;
  height: 0;
  overflow: hidden;
  position: absolute;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  top: 0;
  transition: all ease-in-out 0.8s;
}
.form-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.show-doc-form {
  padding: 20px;
  height: 100vh;
  overflow-y: scroll;
}

.trade-input {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  /* justify-content: center; */
  flex-direction: column;
  /* background-color: red; */
  width: 50%;
}

.trade-input input,
#State,
#bfc,
#blc,
#nlc {
  border: 1px solid gray;
  height: 30px;
  width: 100% !important;
  min-width: 250px;
}

.trade-input label {
  font-size: 0.9rem;
  color: rgb(110, 110, 110);
}
.total-head {
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
}
@media screen and (max-width: 600px) {
  .trade-input {
    width: 100%;
  }
}
@media screen and (max-width: 750px) {
  .doc-form {
    background-color: white;
    width: 100%;
    height: 0;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr;
    top: 0;
    transition: all ease-in-out 0.8s;
  }
  .show-doc-form {
    padding: 20px;
    height: 100vh;
    overflow-y: scroll;
  }
}
