* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.dashboard {
  background-color: white;
  width: 100%;
  /* height: 100vh; */
  display: flex;
  /* align-items: center; */
  /* justify-content: center;
  flex-direction: row;
  position: relative; */

  display: grid;
  /* grid-template-columns: 15% 85%; */
}

.dashboard-container {
  display: flex;
  position: relative;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  /* background-color: #f1f7f7; */
  width: 100%;
  /* height: 80%; */
  margin-top: 10px;
  /* padding: 1rem 3rem 1rem 3rem; */
}

.dash-header {
  /* background-color: yellow; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  border-bottom: 0.0625rem solid #e7eaf3;
  border-top: 0.0625rem solid #e7eaf3;
  margin-bottom: 10px;
  padding: 10px 20px;
  padding-right: 40px;
}

/* .welcome {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
} */

.welcome h1 {
  color: #173e67;
  font-size: 1.7rem;
}

.welcome p {
  color: #888eb0;
  font-size: 0.9rem;
}
.dashboard-wrapper {
  /* display: grid;
  grid-template-rows: repeat(3, 1fr);
  justify-items: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  /* margin-top: 20px; */
  /* background-color: red; */
}

.dash {
  /* background-color: blue; */
  width: 92%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 15px;
  flex-direction: column;
  border: 1px solid #e7eaf3;
  margin-top: 20px;
}

.dash-total {
  /* background-color: rgb(245, 245, 245); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  /* padding: 10px 4px; */
  margin: 15px 0;
  width: 95%;
}
.gray {
  color: #173e67;
}
.wrapper2 {
  margin-top: 20px;
}
@media screen and (max-width: 985px) {
  .welcome h1 {
    font-size: 1.2rem;
  }
  .dashboard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: tomato; */
  }
  .welcome {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .welcome h1 {
    font-size: 1rem;
  }
  .dashboard-wrapper {
    width: 100%;
  }
}
