* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.debtor-info-container {
  /* background-color: red; */
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  /* height: 100%; */
}

.pay-status-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  flex-direction: row;
  padding: 20px 10px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.024) 2.8px 2.8px 2.2px,
    rgba(0, 0, 0, 0.03) 6.7px 6.7px 5.3px,
    rgba(0, 0, 0, 0.03) 12.5px 12.5px 10px,
    rgba(0, 0, 0, 0.035) 22.3px 22.3px 17.9px,
    rgba(0, 0, 0, 0.04) 41.8px 41.8px 33.4px,
    rgba(0, 0, 0, 0.05) 100px 100px 80px;
  border: 1px solid rgba(107, 107, 107, 0.647);
}

.debtor-details-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* height: 70%; */
  margin-top: 30px;
  background-color: white;
  flex-direction: column;
  /* padding: 30px 10px; */
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.024) 2.8px 2.8px 2.2px,
    rgba(0, 0, 0, 0.03) 6.7px 6.7px 5.3px,
    rgba(0, 0, 0, 0.03) 12.5px 12.5px 10px,
    rgba(0, 0, 0, 0.035) 22.3px 22.3px 17.9px,
    rgba(0, 0, 0, 0.04) 41.8px 41.8px 33.4px,
    rgba(0, 0, 0, 0.05) 100px 100px 80px;
  border: 1px solid rgba(107, 107, 107, 0.647);
}

.debtor-details-wrapper {
  /* background-color: blue; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin: 20px 10px;
  padding: 0 20px;
}

.debtor-name-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.debtor-name-wrapper p {
  font-size: 0.8rem;
  color: #888eb0;
}

.debtor-table {
  background-color: #f8f8fb;
  color: black;
  border-radius: unset !important;
  margin-top: 20px;
}

.debtor-table th {
  color: black;
  background-color: #f8f8fb;
  border-bottom: unset;
}

.debtor-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding-left: 20px;
  padding-right: 70px;
  background-color: red;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #1e2139;
  border-radius: 0 0 6px 6px;
  color: white;
}
.unpaid {
  background-color: rgb(255, 231, 231);
  color: rgb(255, 73, 73);
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: 700;
}

.status {
  color: #888eb0;
  font-size: 0.8rem;
}
@media screen and (max-width: 900px) {
  .pay-confirm {
    width: 60%;
  }
}
@media screen and (max-width: 600px) {
  .debtor-info-container {
    width: 90%;
  }
}
@media screen and (max-width: 500px) {
  .pay-confirm {
    width: 90%;
  }
}
